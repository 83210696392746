import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faPen,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import alien from "../images/alien.png"

const ContactUs = ({}) => {
  return (
    <section className="bg-half-170 d-table w-100">
      <div className="bg-overlay bg-overlay-white"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div
              style={{ backgroundImage: `url(${alien})` }}
              className="alien-img team-img"
            />
          </div>
          <div className="col-md-6">
            <div className="title-heading mt-5 pt-4">
              <h1 className="heading">Ready for lift off?</h1>
              <p className="text-dark">
                Here at{" "}
                <span className="text-main font-weight-bold">SpacePilot</span>{" "}
                we have a pilot ready and excited to help.
              </p>
              <div class="custom-form p-4 rounded shadow">
                <form
                  id="contact-form"
                  name="contact"
                  method="POST"
                  data-netlify="true" 
                  data-netlify-honeypot="bot-field"
                  action="/success"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group position-relative">
                        <FontAwesomeIcon icon={faUser} />{" "}
                        <label>
                          Your Name <span class="text-danger">*</span>
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          class="form-control pl-5"
                          placeholder="First Name :"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group position-relative">
                        <FontAwesomeIcon icon={faEnvelope} />{" "}
                        <label>
                          Your Email <span class="text-danger">*</span>
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          class="form-control pl-5"
                          placeholder="Your email :"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group position-relative">
                        <FontAwesomeIcon icon={faPen} />{" "}
                        <label>Message</label>
                        <textarea
                          name="comments"
                          id="comments"
                          rows="4"
                          class="form-control pl-5"
                          placeholder="Your Message :"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <input
                        type="submit"
                        id="submit"
                        name="send"
                        class="submitBnt btn btn-primary btn-block"
                        value="Send Message"
                      />
                      <div id="simple-msg"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
